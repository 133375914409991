import React from 'react';
import styled, { css } from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import Logistic from './assets/Logistic.png';

import { Settings, Work, Laptop } from '@material-ui/icons';

const businessTypeOfJob = './assets/businessTypeOfJob.png';

const ShareICONCSS = css`
  font-size: 17px !important;
  color: #fff !important;
`;
const SettingsIcon = styled(Settings)`
  ${ShareICONCSS};
`;
const WorkIcon = styled(Work)`
  ${ShareICONCSS};
`;
const LaptopIcon = styled(Laptop)`
  ${ShareICONCSS};
`;
const Image = styled.img`
  width: 45px;
  height: 45px;
  margin-top: 1px;
`;

const data = {
  image: (
    <StaticImage
      src={businessTypeOfJob}
      placeholder="blurred"
      alt="Business Jobs"
    />
  ),
  items: [
    {
      id: 1,
      icon: <SettingsIcon />,
      category: 'Operations',
      price: 'Min RM9 - RM10'
    },
    {
      id: 2,
      icon: <Image src={Logistic} alt={Logistic} />,
      category: 'Logistics',
      price: 'Min RM9 - RM11'
    },
    {
      id: 3,
      icon: <WorkIcon />,
      category: 'Sales/Marketing',
      price: 'Min RM9 - RM16'
    },
    {
      id: 4,
      icon: <LaptopIcon />,
      category: 'Admin',
      price: 'Min RM9 - RM10'
    }
  ]
};

export { data };
